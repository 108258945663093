::root {
	--backgroundColor: '#fff';
	--linhas: 1;
}

body {
	background-color: var(--backgroundColor) !important;
}

ul {
	list-style: none outside none;
}
.text-mini {
    font-size: 12px;
}

.cursor-pointer {
    cursor: pointer;
}

/* VOTACAO */
.frb-group {
	margin: 15px 0;
}

.frb ~ .frb {
	margin-top: 15px;
}

.frb input[type="radio"]:empty,
.frb input[type="checkbox"]:empty {
	display: none;
}

.frb input[type="radio"] ~ label:before,
.frb input[type="checkbox"] ~ label:before {
	font-family: FontAwesome;
	content: '\f096';
	position: absolute;
	top: 50%;
	margin-top: -11px;
	left: 15px;
	font-size: 22px;
}

.frb input[type="radio"]:checked ~ label:before,
.frb input[type="checkbox"]:checked ~ label:before {
	content: '\f046';
}

.frb input[type="radio"] ~ label,
.frb input[type="checkbox"] ~ label {
	position: relative;
	cursor: pointer;
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #f2f2f2;
}

.frb input[type="radio"] ~ label:focus,
.frb input[type="radio"] ~ label:hover,
.frb input[type="checkbox"] ~ label:focus,
.frb input[type="checkbox"] ~ label:hover {
	box-shadow: 0px 0px 3px #333;
}

.frb input[type="radio"]:checked ~ label,
.frb input[type="checkbox"]:checked ~ label {
	color: #fafafa;
}

.frb input[type="radio"]:checked ~ label,
.frb input[type="checkbox"]:checked ~ label {
	background-color: #f2f2f2;
}

.frb.frb-default input[type="radio"]:checked ~ label,
.frb.frb-default input[type="checkbox"]:checked ~ label {
	color: #333;
}

.frb.frb-primary input[type="radio"]:checked ~ label,
.frb.frb-primary input[type="checkbox"]:checked ~ label {
	background-color: #337ab7;
}

.frb.frb-success input[type="radio"]:checked ~ label,
.frb.frb-success input[type="checkbox"]:checked ~ label {
	background-color: #5cb85c;
}

.frb.frb-info input[type="radio"]:checked ~ label,
.frb.frb-info input[type="checkbox"]:checked ~ label {
	background-color: #5bc0de;
}

.frb.frb-warning input[type="radio"]:checked ~ label,
.frb.frb-warning input[type="checkbox"]:checked ~ label {
	background-color: #f0ad4e;
}

.frb.frb-danger input[type="radio"]:checked ~ label,
.frb.frb-danger input[type="checkbox"]:checked ~ label {
	background-color: #d9534f;
}

.frb input[type="radio"]:empty ~ label span,
.frb input[type="checkbox"]:empty ~ label span {
	display: inline-block;
}

.frb input[type="radio"]:empty ~ label span.frb-title,
.frb input[type="checkbox"]:empty ~ label span.frb-title {
	font-size: 16px;
	font-weight: 700;
	margin: 5px 5px 5px 50px;
}

.frb input[type="radio"]:empty ~ label span.frb-description,
.frb input[type="checkbox"]:empty ~ label span.frb-description {
	font-weight: normal;
	font-style: italic;
	color: #999;
	margin: 5px 5px 5px 50px;
}

.frb input[type="radio"]:empty:checked ~ label span.frb-description,
.frb input[type="checkbox"]:empty:checked ~ label span.frb-description {
	color: #fafafa;
}

.frb.frb-default input[type="radio"]:empty:checked ~ label span.frb-description,
.frb.frb-default input[type="checkbox"]:empty:checked ~ label span.frb-description {
	color: #999;
}

.table-fixed {
	width: 100%;
  }
  .table-fixed tbody {
	height: 200px;
	overflow-y: auto;
	width: 100%;
  }
  .table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
	display: block;
  }
  .table-fixed tbody td {
	float: left;
  }
  .table-fixed thead tr th {
	float: left;
  }

.modal-evoto {
	width: 98%;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
}

.modal-evoto .modal-body {
	max-height: 75vh;
	overflow-y: scroll;
}

.print-template {
	position: absolute;
    bottom: 20px;
    left: 40px;
    right: 40px;
    height: 50px;
}

.view-texto-imprimir {
	font-family: "DejaVu Sans", "Arial", sans-serif;
	font-size: 12px;
}

.header-imprimir {
	font-family: "DejaVu Sans", "Arial", sans-serif;
	font-size: 9px;
}

.k-pdf-export table {
	font-family: "DejaVu Sans", "Arial", sans-serif;
	font-size: 8px;
}

.k-pdf-export .view-texto-imprimir {
	font-family: "DejaVu Sans", "Arial", sans-serif;
	font-size: 9px;
}

.k-pdf-export table {
	font-family: "DejaVu Sans", "Arial", sans-serif;
	font-size: 8px;
}

.table-imprimir {
     font-family: "DejaVu Sans", "Arial", sans-serif;
     font-size: 8px;
}

.small-imprimir {
	font-family: "DejaVu Sans", "Arial", sans-serif;
	font-size: 7px;
}

.bold-logo-imprimir {
	font-family: "DejaVu Sans Bold", "Arial", sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 9px;
}

.bold-title-imprimir {
	font-family: "DejaVu Sans Bold", "Arial", sans-serif;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 10px;
	margin-top: 10px;
}


   @font-face {
     font-family: "DejaVu Sans";
     src: url("../fonts/DejaVuSans.ttf") format("truetype");
   }

   @font-face {
     font-family: "DejaVu Sans Bold";
     font-weight: bold;
     src: url("../fonts/DejaVuSans-Bold.ttf") format("truetype");
   }

   @font-face {
     font-family: "DejaVu Sans";
     font-style: italic;
     src: url("../fonts/DejaVuSans-Oblique.ttf") format("truetype");
   }

   @font-face {
     font-family: "DejaVu Sans";
     font-weight: bold;
     font-style: italic;
     src: url("../fonts/DejaVuSans-ExtraLight.ttf") format("truetype");
   }


.e-voto-logo {
		width: 90px;
		height: 23px;
		margin-top: -10px;
		fill: #fff;
}

.e-voto-logo-login {
		width: 100%;
		height: 80px;
		stroke: #6c757d;
    stroke-width: 3px;
    stroke-dasharray: 1788;
    animation: 15s escrever;
}

  @keyframes escrever {
    0% {
        stroke-dashoffset: 1788;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

.fotoCandidato {
	padding: 10px;
	cursor: pointer;
    display: table;
}

input[type="file"] {
    display: none;
}

.invalid-feedback-date {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.modal-footer-apuracao {
	justify-content: center !important;
}

.align-items-center {
  -ms-flex-align: center!important;
  align-items: center!important;
}

.d-flex {
  display: -ms-flexbox!important;
  display: flex!important;
}

.break-container {
	margin: 0 -100% !important; /* old browsers fallback */
	margin: 0 calc(50% - 49vw) !important;
}

.relatorio-votacao-candidato .row,
.relatorio-votacao-votos .col-12 {
	border-bottom: solid rgb(222, 226, 230) 1px;
}

.relatorio-votacao-candidato .row:first-child,
.relatorio-votacao-candidato .row:last-child,
.relatorio-votacao-votos .col-12:first-child,
.relatorio-votacao-votos .col-12:last-child {
	border-bottom: none;
}

kendo-pdf-document .linha-candidato {
	max-height: calc(16px * var(--linhas));
}

.titulo-simulada {
	text-align: center;
	color:#912c2c;
	margin: 10px auto;
}

.switch-tipo-eleicao {
	padding-right: 0 !important;
}

.switch-tipo-eleicao .btn {
	padding: 6px !important;
}

@media(max-width: 997px){
	.switch-tipo-eleicao {
		display: flex;
		flex-direction: row;
		flex: 1;
		align-items: center;
		justify-content: center;
		margin-bottom: 15px;
	}

	.switch-tipo-eleicao .btn {
		padding: 6px 12px !important;
	}
}